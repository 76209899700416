import axios from 'axios';

import {
    getHeaders,
    getCatchBlock,
    getSuccessNotification,
} from './apiServiceHelpers';

export const get = async (
    endPoint,
    payload = {},
    useLoginApiBase = false,
    sendAuthHeader = false,
    cb,
    showNotification = false,
    backupToken = null,
    order_id = null,
    retainUser = false //check for logging out user or not
) => {
    try {
        let obj = await getHeaders(
            'get',
            endPoint,
            sendAuthHeader,
            'json',
            payload,
            {},
            useLoginApiBase,
            backupToken,
            order_id,
            retainUser
        );
        const response = await axios(obj);
        if (response.data.success === true) {
            if (showNotification) getSuccessNotification(response.data);
            return response.data.data;
        } else {
            cb && cb(response);
            throw { ...response.data.message };
        }
    } catch (error) {
        cb && cb(error);
        getCatchBlock(error);
    }
};

export const post = async (
    endPoint,
    payLoad,
    responseType = 'json',
    useLoginApiBase = false,
    sendAuthHeader = false,
    cb,
    backupToken = null,
    order_id = null,
    retainUser = false //check for logging out user or not
) => {
    try {
        let obj = await getHeaders(
            'post',
            endPoint,
            sendAuthHeader,
            responseType,
            {},
            payLoad,
            useLoginApiBase,
            backupToken,
            order_id,
            retainUser
        );

        const response = await axios(obj);
        if (response.data.success === true) {
            if (endPoint?.includes('easy_settle_bid'))
                return response.data.data;
            getSuccessNotification(response.data);
            return response.data.data;
        } else {
            throw { ...response.data.message };
        }
    } catch (error) {
        getCatchBlock(error);
    }
};

export const postWithHtmlRedirect = async (
    endPoint,
    payLoad,
    useLoginApiBase = false,
    cb,
    backupToken = null,
    order_id = null,
    retainUser = false //check for logging out user or not
) => {
    try {
        let obj = await getHeaders(
            'postWithHtml',
            endPoint,
            true,
            '',
            {},
            payLoad,
            useLoginApiBase,
            backupToken,
            order_id,
            retainUser
        );

        const response = await axios(obj);

        if (response.data.success === true) {
            getSuccessNotification(response.data);
            return response.data.data;
        } else {
            throw { ...response.data.message };
        }
    } catch (error) {
        getCatchBlock(error);
    }
};

export const patch = async (
    endPoint,
    payLoad,
    responseType = 'json',
    useLoginApiBase = false,
    cb,
    backupToken = null,
    order_id = null,
    retainUser = false //check for logging out user or not
) => {
    try {
        let obj = await getHeaders(
            'patch',
            endPoint,
            true,
            responseType,
            {},
            payLoad,
            useLoginApiBase,
            backupToken,
            order_id,
            retainUser
        );

        const response = await axios(obj);
        if (response.data.success === true) {
            getSuccessNotification(response.data);
            return response.data.data;
        } else {
            throw { ...response.data.message };
        }
    } catch (error) {
        getCatchBlock(error);
    }
};
