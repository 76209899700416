import { Drawer } from 'antd';
import React from 'react';
import styles from '../../pages/v2/Layout.module.css';
import drawerStyles from './SideDrawer.module.css';
import { withRouter } from 'next/router';
import {
    GAEventHandler,
    getSvgForSideDrawer,
    sessionStorageKeys,
} from '../../utils';
import { connect } from 'react-redux';
import Button from '../Button/CustomButton';
class SideDrawer extends React.Component {
    constructor(props) {
        super(props);
    }

    closeDrawer = () => {
        this.setState({
            show: false,
        });
    };

    redirectToCreateAccount = () => {
        GAEventHandler(
            'Sidemenu_CreateAccount',
            'Create Account',
            'Sidemenu_CreateAccount'
        );

        const pathname = window.location.pathname;
        sessionStorage.setItem(
            sessionStorageKeys.CREATE_ACC_HIT_URL,
            window.location.href
        );
        window.location.href = `/v2/create-account?bckUrl=${pathname}`;
    };

    getActiveTab = (item) => {
        if (!this.props.router?.pathname) return;
        return this.props.router?.pathname?.indexOf(item) > -1;
    };

    formateName = () => {
        const { userObj } = this.props;
        let { fname = '', lname = '' } = userObj;
        if (!lname) {
            lname = '';
        }
        if (fname?.length + lname?.length <= 15) return `${fname} ${lname}`;
        else {
            var tempString = `${fname} ${lname}`;
            return `${tempString?.slice(0, 15)}...`;
        }
    };

    redirectToHome = () => {
        const { router, closeSideDrawer } = this.props;
        router.push('/home');
        closeSideDrawer();
    };

    redirectToTranscations = () => {
        GAEventHandler(
            'Sidemenu_Order&T',
            'Orders and Transactions',
            'Sidemenu_Order&T'
        );
        const { router, closeSideDrawer } = this.props;
        // let url = router.asPath.split('?');
        router.push(`/v2/transactionHistory?bckUrl=true&goBack=true`);
        closeSideDrawer();
    };

    redirectToComplaints = () => {
        GAEventHandler(
            'Sidemenu_ComplaintHistory',
            'Complaint History',
            'Sidemenu_ComplaintHistory'
        );

        const { router, closeSideDrawer } = this.props;
        router.push(`/v2/complaintHistory?bckUrl=true&goBack=true`);
        closeSideDrawer();
    };
    redirectToProfileSetting = () => {
        GAEventHandler(
            'Sidemenu_profilesetting',
            'Profile Settings',
            'Sidemenu_profilesetting'
        );
        const { router, closeSideDrawer } = this.props;
        router.push(`/v2/profileSetting?bckUrl=true&goBack=true`);
        closeSideDrawer();
    };

    render() {
        const {
            onClickLoginBtn,
            userLoggedIn,
            showSideDrawer,
            closeSideDrawer,
            onClickEmailAdd,
            userEmail,
            userPhone,
        } = this.props;

        return (
            <Drawer
                title=""
                placement="right"
                onClose={closeSideDrawer}
                visible={showSideDrawer}
                bodyStyle={{
                    padding: '0px',
                    paddingTop: '66px',
                    paddingBottom: '45px',
                }}
                className="side-drawer-testing"
                height="450px"
                width="330px"
            >
                <div>
                    {!userLoggedIn ? (
                        <div className={drawerStyles.sideDrawerUserProfile}>
                            <img className="" src="/drawerAvatar.svg"></img>
                            <div className={drawerStyles.sideDrawerTagLine}>
                                Stay on-track with your bill payments by logging
                                in
                            </div>
                            <div className={drawerStyles.loginButtonWrapper}>
                                <Button
                                    text="Login"
                                    click={() => onClickLoginBtn(true)}
                                />
                            </div>

                            <div
                                className={`${drawerStyles.createAccountActionSideDrawer}`}
                            >
                                Don’t Have an account ?
                                <span
                                    className={styles.inLineAction}
                                    onClick={this.redirectToCreateAccount}
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {' '}
                                    Create an account
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className={drawerStyles.sideDrawerUserProfile}>
                            <img className="" src="/drawerAvatar.svg"></img>
                            <div className={drawerStyles.sideDrawerUserName}>
                                {this.formateName()}
                            </div>
                            <div className={drawerStyles.sideDrawerPhoneNumber}>
                                {'+91 ' + userPhone}
                            </div>
                            <div className={drawerStyles.emailRow}>
                                {userEmail?.length ? (
                                    <p
                                        className={drawerStyles.emailText}
                                        style={{ color: '#535353' }}
                                    >
                                        {userEmail}
                                    </p>
                                ) : (
                                    <>
                                        <p className={drawerStyles.emailText}>
                                            Email address
                                        </p>
                                        <p
                                            className={
                                                drawerStyles.emailEditText
                                            }
                                            onClick={onClickEmailAdd}
                                        >
                                            Add
                                        </p>{' '}
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="sideDrawerItems">
                        <ul className={drawerStyles.menuList}>
                            <li
                                className={`${drawerStyles.menuItem} ${
                                    this.getActiveTab('home')
                                        ? drawerStyles.active
                                        : ''
                                }`}
                                onClick={this.redirectToHome}
                            >
                                <div
                                    className={drawerStyles.menuItemIconWrapper}
                                >
                                    {getSvgForSideDrawer(
                                        'home',
                                        this.getActiveTab('home') && 'home'
                                    )}
                                    Home
                                </div>
                                <img
                                    className={drawerStyles.openArrow}
                                    src="/openArrow.svg"
                                ></img>
                            </li>
                            <li
                                className={`${drawerStyles.menuItem} ${
                                    this.getActiveTab('transaction')
                                        ? drawerStyles.active
                                        : ''
                                } ${
                                    !userLoggedIn ? drawerStyles.disabled : ''
                                }`}
                                onClick={this.redirectToTranscations}
                            >
                                <div
                                    className={drawerStyles.menuItemIconWrapper}
                                >
                                    {getSvgForSideDrawer(
                                        'transaction',
                                        this.getActiveTab('transaction') &&
                                            'transaction'
                                    )}
                                    Orders & Transactions
                                </div>
                                <img
                                    className={drawerStyles.openArrow}
                                    src="/openArrow.svg"
                                ></img>
                            </li>

                            <li
                                className={`${drawerStyles.menuItem} ${
                                    this.getActiveTab('complaint')
                                        ? drawerStyles.active
                                        : ''
                                } ${
                                    !userLoggedIn ? drawerStyles.disabled : ''
                                }`}
                                onClick={this.redirectToComplaints}
                            >
                                <div
                                    className={drawerStyles.menuItemIconWrapper}
                                >
                                    {getSvgForSideDrawer(
                                        'complaint',
                                        this.getActiveTab('complaint') &&
                                            'complaint'
                                    )}
                                    Complaint History
                                </div>
                                <img
                                    className={drawerStyles.openArrow}
                                    src="/openArrow.svg"
                                ></img>
                            </li>

                            <li
                                className={`${drawerStyles.menuItem} ${
                                    this.getActiveTab('profile')
                                        ? drawerStyles.active
                                        : ''
                                } ${
                                    !userLoggedIn ? drawerStyles.disabled : ''
                                }`}
                                onClick={this.redirectToProfileSetting}
                            >
                                <div
                                    className={drawerStyles.menuItemIconWrapper}
                                >
                                    {getSvgForSideDrawer(
                                        'profile',
                                        this.getActiveTab('profile') &&
                                            'profile'
                                    )}
                                    Profile Settings
                                </div>
                                <img
                                    className={drawerStyles.openArrow}
                                    src="/openArrow.svg"
                                ></img>
                            </li>
                        </ul>
                    </div>
                </div>
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userObj: state.payments.userObj,
        userPhone: state.payments.userPhone,
        userEmail: state.payments.userEmail,
    };
};

export default connect(mapStateToProps)(withRouter(SideDrawer));
