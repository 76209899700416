import '../styles/globals.css';
import 'antd/dist/antd.css';
import LoadingBar from 'react-top-loading-bar';
import React, { useRef, useEffect, useState } from 'react';
import Layout from './v2/Layout';
import Head from 'next/head';
import { sessionStorageKeys } from '../utils';
import * as ga from '../lib/analytics';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { wrapper, store } from '../store/store';
import { Provider } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';

function MyApp({ Component, pageProps }) {
    const ref = useRef(null);
    const [isMobile, setIsMobile] = useState(true);
    const [isStandaoloneApp, setIsStandaloneApp] = useState(false);
    const hideTopLoader = () => {
        ref.current.complete();
    };
    const showTopLoader = () => {
        ref.current.continuousStart();
    };

    const router = useRouter();

    useEffect(() => {
        const isMobile = window.navigator.userAgent.match(
            /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
        );
        const isStandaoloneMode = window.matchMedia(
            '(display-mode: standalone)'
        ).matches;
        setIsMobile(isMobile);
        setIsStandaloneApp(isStandaoloneMode);
        sessionStorage.setItem(
            sessionStorageKeys.IS_MOBILE,
            isMobile ? true : false
        );
        if (process.env.NEXT_PUBLIC_ENABLE_SENTRY) {
            Sentry.init({
                dsn: 'https://368e1d4daeed495b9142ce85a373c6f2@o1166858.ingest.sentry.io/6343215',
                tracesSampleRate: 1.0,
                ignoreFile: '.sentryclirc',
                ignore: ['node_modules'],
                configFile: 'sentry.properties',
                environment: process.env.NODE_ENV,
                release: process.env.NEXT_PUBLIC_RELEASE,
                ignoreErrors: [
                    'getReadModeConfig',
                    'getReadModeExtract',
                    'getReadModeRender',
                ],
            });
        }
    }, []);

    useEffect(() => {
        //disable back button on some routes
        router.beforePopState(({ url, as, options }) => {
            if (
                url.includes('/payment-progress') ||
                url.includes('/upi-landing') ||
                url.includes('/upi-tray') ||
                url.includes('/upi-qr') ||
                url.includes('/payment-status')
            ) {
                if (!alert('You will be redirected to Home screen')) {
                    router.push('/home');
                }
                return false;
            }
            return true;
        });
        const handleRouteChange = (url) => {
            ga.pageview(url);
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <div className="appContainer">
            <Head>
                {/* <script
                    src="https://kit.fontawesome.com/a63405a4b4.js"
                    crossorigin="anonymous"
                ></script> */}
                <link rel="manifest" href="../app.webmainfest" />
                <meta name="theme-color" content="#317EFB" />
                <link rel="icon" type="image/gif/png" href="/billzy.png"></link>
                <link
                    href="https://fonts.googleapis.com/css?family=Roboto"
                    rel="stylesheet"
                    type="text/css"
                ></link>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                ></meta>
                <script type="text/javascript" src="/sw-register.js"></script>
                <script
                    type="text/javascript"
                    src="https://code.jquery.com/jquery-2.1.1.min.js"
                ></script>
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id='${process.env.NEXT_GA_ID}'`}
                />

                <script
                    dangerouslySetInnerHTML={{
                        __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_GA_ID}', {
              page_path: window.location.pathname,
              });
            `,
                    }}
                />
            </Head>
            <LoadingBar color="#f11946" ref={ref} />
            {Component.staticName != 'Custom404' &&
            Component.staticName != 'errorPage' ? (
                <Layout isMobile={isMobile} isStandaoloneApp={isStandaoloneApp}>
                    <Provider store={store}>
                        <Component
                            {...pageProps}
                            hideTopLoader={hideTopLoader}
                            showTopLoader={showTopLoader}
                            isMobile={isMobile}
                        />
                    </Provider>
                </Layout>
            ) : (
                <Component
                    {...pageProps}
                    hideTopLoader={hideTopLoader}
                    showTopLoader={showTopLoader}
                    isMobile={isMobile}
                />
            )}
        </div>
    );
}
export default wrapper.withRedux(MyApp);
