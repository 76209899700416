export const pageview = (url) => {
    if (window && window.gtag) {
        window.gtag('config', `'${process.env.NEXT_GA_ID}'`, {
            page_path: url,
        });
    }
};

export const event = ({ action, params }) => {
    window.gtag('event', action, params);
};
